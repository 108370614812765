<template>

<app-content :loading="is.loading">

	<app-content-head title="Edit block" :back="$route.meta.back" :readonly="$authorised('con/pages/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/pages/access', 'read')">

			<app-input-text label="Name" v-model="model.name" :validation="$v.model.name" placeholder="Enter name..." />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/pages/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.text">

			<app-input-content v-model="model.text.content" theme="information" :validation="$v.model.text.content" placeholder="Enter content..." />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/pages/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.banner">

			<app-input-image v-model="model.banner.image" :validation="$v.model.banner.image" label="Image" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/pages/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.image">

			<app-input-image v-model="model.image.image" :validation="$v.model.image.image" label="Image" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/pages/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.gallery" :not-padded="true">

			<app-input-gallery v-model="model.gallery.images" :validation="$v.model.gallery.images" placeholder="Upload image" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/pages/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.video">

			<app-input-text label="URL" v-model="model.video.source" :validation="$v.model.video.source" placeholder="Enter URL (e.g. youtube)" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/pages/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.map">

			<app-input-text label="URL" v-model="model.map.url" :validation="$v.model.map.url" placeholder="Enter URL (e.g. google maps)" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/pages/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.capture">

			<app-input-inputs label="Inputs" v-model="model.capture.inputs" :validation="$v.model.capture.inputs" placeholder="No inputs added." :options="inputOptions" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/pages/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.feature">

			<app-input-text label="Name" v-model="model.feature.title" :validation="$v.model.feature.title" placeholder="Enter name" />
			<app-input-text label="Description" :autogrow="true" v-model="model.feature.description" :validation="$v.model.feature.description" placeholder="Enter description" />
			<app-input-text label="URL" v-model="model.feature.url" :validation="$v.model.feature.url" placeholder="Enter URL" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { url } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/pages/access', 'read'),
			references: {
				badges: []
			},
			inputOptions: {
				validation: [1]
			},
			model: {
				id: false,
				type: false,
				text: {
					content: ''
				},
				banner: {
					image: ''
				},
				image: {
					image: ''
				},
				gallery: {
					images: []
				},
				video: {
					source: ''
				},
				map: {
					url: ''
				},
				capture: {
					inputs: []
				},
				feature: {
					title: '',
					description: '',
					url: ''
				}
			}
		}

	},

	validations: {
		model: {
			text: {},
			banner: {},
			gallery: {},
			image: {},
			video: {
				source: {
					url
				}
			},
			map: {
				url: {
					url
				}
			},
			feature: {
				url: {
					url
				}
			},
			capture: {}
		}
	}

}

</script>

<style scoped>

</style>